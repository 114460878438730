
import Vue from "vue";
import { checkout, ICompany, IPaymentOrder, PaymentStatus } from "@/api";
import { capitalize, capitalizeBy_, getDatetime } from "@/utils";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "InvoiceHistory",
  components: {},
  data() {
    return {
      items: [] as IPaymentOrder[],
      headers: [
        { text: "DATE ISSUED", value: "created_datetime" },
        // { text: "INVOICE #", value: "invoice_id" },
        { text: "TRANSACTION ID", value: "order_id" },
        { text: "TRANSACTION TYPE", value: "order_type" },
        { text: "PAYMENT METHOD", value: "last4" },
        { text: "AMOUNT", value: "order_amount" },
        { text: "STATUS", value: "payment_status" },
        { text: "", value: "action" },
      ],
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      try {
        this.items = (await checkout.getPaymentOrders(this.company.id)).data;
        this.items.forEach((item: any) => {
          item.created_datetime = getDatetime(item.created_datetime);
          item.last4 = "Card ending in ";
          try {
            item.last4 =
              item.last4 + JSON.parse(item.payment_method_info)?.card?.last4;
          } catch (e) {
            console.log("Error while parsing payment_method_info:", e);
            item.last4 = item.last4 + "XXXX";
          }
          item.order_amount = "$" + (item.order_amount / 100).toFixed(2);
          item.payment_status = capitalize(item.payment_status);
          item.order_type = capitalizeBy_(item.order_type)
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getStatusClass(item: IPaymentOrder): string {
      if (!item.payment_status) {
        if (item.order_amount <= 0) {
          return "status-paid";
        }
        return "status-declined";
      }
      switch (item.payment_status.toLowerCase()) {
        case PaymentStatus.Paid:
          return "status-paid";
        default:
          return "status-declined";
      }
    },
    getAmountClass(item: IPaymentOrder): string {
      if (!item.payment_status) {
        if (item.order_amount <= 0) {
          return "";
        }
        return "status-declined";
      }
      switch (item.payment_status.toLowerCase()) {
        case PaymentStatus.Paid:
          return "";
        default:
          return "status-declined";
      }
    },
    download(id: number) {
      const url =
        window.location.origin + "/invoice/" + id + "?method=download";
      window.open(url, "_blank");
    },
  },
  watch: {
    company: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
});
