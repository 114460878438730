
import Vue from "vue";
import { checkout, ICompany, ICompanyAccount, IPaymentOrder } from "@/api";
import { getDate } from "@/utils";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
import { Actions } from "@/store/models";
import InvoiceHistory from "@/components/accountManagement/billing/InvoiceHistory.vue";
import BackButton from "@/components/common/BackButton.vue";

export default Vue.extend({
  name: "Billing",
  components: {
    CompanyHeader,
    InvoiceHistory,
    BackButton,
  },
  data() {
    return {
      items: [] as IPaymentOrder[],
      headers: [
        { text: "DATE ISSUED", value: "created_datetime" },
        // { text: "INVOICE #", value: "id" },
        { text: "TRANSACTION ID", value: "order_id" },
        { text: "PAYMENT METHOD", value: "payment_method_info.card.last4" },
        { text: "PAYMENT STATUS", value: "payment_status" },
        { text: "", value: "action" },
      ],
      billingInfo: {} as any,
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
    account: function (): ICompanyAccount {
      return this.$store.state.company?.account;
    },
    details: function (): any {
      const { cao } = this.account || {};
      return [
        { label: "Account Number", text: this.company?.account_number },
        {
          label: "Renewal Date",
          text: getDate(this.company?.renewal_date as any),
        },
        {
          label: "Account Owner",
          text: `${cao?.first_name} ${cao?.last_name}`,
        },
        { label: "Company", text: cao?.company?.name },
      ];
    },
  },
  created() {
    this.getBillingInfo();
  },
  methods: {
    async getBillingInfo() {
      try {
        const balance = (await checkout.getOutstandingDues(this.company.id))
          .data.total_due;
        this.billingInfo = {
          nextPaymentDate: getDate(this.company?.renewal_date as any) ,
          seats: this.account?.license_info?.total_users_limit,
          storage: this.account?.license_info?.total_storage_limit,
          balance: `$${balance}` || "N/A",
        };
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    isOutStandingBalanceAvailable(item: string): string {
      if(item === '$0'){
        return 'false';
      }
      return 'true';
    },
    editBilling() {
      this.$router.push({ path: "billingedit" });
    },
  },
});
